import {getComponentData} from "../api/get-component-data";
import {MS_IN_DAY, MS_IN_HOUR, MS_IN_MINUTE} from "../config/date-time";
import {MAX_ALERTS_AGE} from "../config/widget";
import {deleteKeys, setKeys, getKey} from "../helpers/local-storage";

const EmergencyAlertWidget = () => ({
    location: null,
    alerts: [],
    timestamp: Date.now(),

    init() {
        this.$watch("$store.ProfileAgent.currentLocation", (location) => {
            this.location = location.split(" (").at(0);
            this.updateAlerts();
        });
    },

    updateAlerts() {
        this.isRecent(getKey("emergency_alerts_updated")) &&
        this.isStorageLocation(getKey("emergency_alerts_location")) ?
            this.getAlerts()
            :
            this.request();
    },

    isRecent(timestamp) {
        return (Date.now() - timestamp) / 1000 <= MAX_ALERTS_AGE;
    },

    isStorageLocation(location) {
        return location === this.location;
    },

    request() {
        const {placementId} = this.$refs.BaseData.dataset;
        const url = `/api/widget/${placementId}/`;

        getComponentData(url, {location: this.location}, true)
            .then(response => {
                const {result} = JSON.parse(response);
                this.alerts = result;
                this.storeData(response);
            })
            .catch(err => console.error(err));
    },

    getAlerts() {
        try {
            this.alerts = JSON.parse(getKey("emergency_alerts_json"));
        } catch (e) {
            deleteKeys([
                "emergency_alerts_json",
                "emergency_alerts_updated",
                "emergency_alerts_location"
            ]);
        }
    },

    storeData() {
        setKeys([
            ["emergency_alerts_updated", this.timestamp],
            ["emergency_alerts_json", JSON.stringify(this.alerts)],
            ["emergency_alerts_location", this.location],
        ]);
    },

    formatDate(timestamp) {
        const rtf = new Intl.RelativeTimeFormat("nl", {style: "long"});
        const diff = this.timestamp - Date.parse(timestamp);
        const diffInUnits = this.getRelativeDiff(diff);
        return rtf.format(diffInUnits.value, diffInUnits.unit);
    },

    getRelativeDiff(diff) {
        let obj;
        switch (true) {
            case diff > MS_IN_DAY:
                obj = {unit: "day", value: this.getDiffAmount(diff, MS_IN_DAY)};
                break;
            case diff > MS_IN_HOUR:
                obj = {unit: "hour", value: this.getDiffAmount(diff, MS_IN_HOUR)};
                break;
            case diff > MS_IN_MINUTE:
                obj = {unit: "minute", value: this.getDiffAmount(diff, MS_IN_MINUTE)};
                break;
        }
        return obj;
    },

    getDiffAmount(diff, unit) {
        return Math.round(diff / unit * -1);
    }

});

export default EmergencyAlertWidget;
