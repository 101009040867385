const WIDGET_RELOAD_TIMEOUT = 60000;
const LABELED_LINKS_MAX = 10;

const ITEMS_PER_PAGE = 5;
const MAX_ALERTS_AGE = 120;

export {
    LABELED_LINKS_MAX,
    WIDGET_RELOAD_TIMEOUT,
    ITEMS_PER_PAGE,
    MAX_ALERTS_AGE
};
