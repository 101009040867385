import gettext from "../gettext";
import {convertNodetoJQuery} from "../helpers/convert-node-to-jquery";
import {logClick} from "../tracking/tracking";
import isValidUrl from "../valid-url";


const PersonalLinks = () => ({
    isEditing: false,
    links: null,
    linksPerPage: 5,
    currentPage: 1,
    currentLinks: [],
    editLinkId: null,
    editLinkHasTrackingId: false,
    linkHref: "https://",
    linkTitle: null,
    editedLink: null,

    init() {
        this.$watch("$store.ProfileAgent.data", (data) => {
            if (data.links) {
                this.links = data.links;
                if (data.links_per_page) {
                    this.linksPerPage = data.links_per_page;
                }
                this.links && this.$store.ProfileAgent.showPaginatedLinks(this.currentPage);
            } else {
                this.addDefaultLinksToProfile();
            }
        });

        this.$watch("$store.PersonalLinks.selectedLink", (link) => {
            this.editedLink = link;
            this.linkHref = link.url;
            this.linkTitle = link.title;
            this.submit(false);
        });

        this.$watch("$store.PersonalLinks.paginatedLinks", (links) => {
            this.currentLinks = links;
        });


    },

    addDefaultLinksToProfile() {
        const {UserLinks} = this.$refs;
        const defaultLinkElems = Array.from(UserLinks.querySelectorAll("div > li"));

        const defaultLinks = () => defaultLinkElems.map(link => (
            {
                url: link.dataset.url,
                title: link.dataset.title,
            }
        ));
        this.$store.ProfileAgent.update("links", defaultLinks);

    },

    editLink(link) {
        this.editLinkHasTrackingId = !!link.tracking_id;
        this.editedLink = link;

        this.linkHref = link.url;
        this.linkTitle = link.title;
    },

    removeLink(link) {
        const {id, url} = link;
        const shouldRemove = window.confirm(gettext("Are you sure you want to delete this link?"));
        const linkList = () => this.links.filter(link => link.id !== id);

        if (shouldRemove) {
            this.$store.ProfileAgent.update("links", linkList).then(() => {
                this.logger("delete-link", url);
            });
        }

    },

    submit(showAlert = true) {
        if (!isValidUrl(this.linkHref)) {
            alert(gettext("The URL you entered is incomplete or not correct."));
            return;
        }

        const link = {
            id: this.editedLink ? this.editedLink.id : undefined,
            url: this.linkHref,
            title: this.linkTitle,
            tracking_id: this.editedLink ? this.editedLink.tracking_id : undefined,
            zlostat_id: this.editedLink ? this.editedLink.zlostat_id : undefined,
        };

        let linkList;
        if (link.id) {
            const currentLinks = this.links;
            for (let i = 0; i < currentLinks.length; i++) {
                if (currentLinks[i].id === link.id) {
                    currentLinks[i] = link;
                }
            }
            linkList = () => {
                currentLinks;
            };
        } else {
            const currentLinks = this.links.reduce((acc, curr) => {
                if (curr.url.startsWith("#")) {
                    acc.push({
                        ...curr,
                        url: `${window.location.origin}/${curr.url}`
                    });
                } else {
                    acc.push(curr);
                }
                return acc;
            }, []);
            linkList = () => currentLinks.concat(link);
        }

        this.$store.ProfileAgent.update("links", linkList).then(() => {
            if (showAlert) {
                if (link.id) {
                    this.$store.Toast.add({type: "success", text: gettext("The link has been saved.")});
                    this.logger("edit-links", link.url);
                } else {
                    this.$store.Toast.add({type: "success", text: gettext("The link has been added to your personal links.")});
                    this.logger("add-link-manual", link.url);
                }
            }
            this.linkHref = "https://";
            this.linkTitle = "";
            this.editedLink = null;
            this.editLinkHasTrackingId = false;
        });
    },

    logger(action, label, category = "personal-links") {
        const {BaseData} = this.$refs;
        logClick(convertNodetoJQuery(BaseData), {
            event_category: category,
            event_action: action,
            event_label: label
        });
    }
});

export default PersonalLinks;
