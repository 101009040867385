import "../../scss/widgets/weather.scss";
import LocationBasedWidget from "./locationbasedwidget";

/*

The weather widget is a location based widget that shows the current temperature
and icon based on the location you've setup in your profile or in the search bar.

The 4 day forecast is rendered from the backend; and will not be changed if the
location changes.
*/

export class WeatherWidget extends LocationBasedWidget {
    displayLocation() {
        super.displayLocation();

        const params = {lat: this.lat, lon: this.lon};
        this.reloadComponentHtml("[data-widget-content]", params);
    }
}
