import getConfig from "../../../umbrella/js/config";
import {emailRegexp} from "../validation/validation-checks";

const NewsletterWidget = () => ({

    data: {
        firstName: "",
        lastName: "",
        email: "",
        gender: ""
    },

    isSubscribed: false,
    isSubmitted: false,
    isGenericError: false,
    isEmailValid: true,
    firstNameAdded: true,
    lastNameAdded: true,
    genderAdded: true,

    init() {

        this.$watch("$store.ProfileAgent.data", (data) => {
            if (data.newsletter) {
                this.isSubscribed = true;
            } else if (data.email) {
                this.data = data;
            }
        });
    },

    submit() {

        if (this.checkFields(this.data)) {

            const fetchConfig = {
                method: "POST",
                mode: "same-origin",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                referrerPolicy: "no-referrer-when-downgrade",
                body: JSON.stringify(this.data),
            };

            fetch(getConfig("newsletterApiUrl"), fetchConfig)
                .then(response => {
                    if (response.status === 201) {
                        this.isSubmitted = true;
                    } else {
                        this.isGenericError = true;
                    }
                })
                .catch(() => {
                    this.isGenericError = true;
                })
            ;
        }
    },

    checkFields(data) {

        this.isEmailValid = emailRegexp.test(data.email);
        this.firstNameAdded = !!this.data.firstName;
        this.lastNameAdded = !!this.data.lastName;
        this.genderAdded = !!this.data.gender;

        return this.isEmailValid && this.firstNameAdded && this.lastNameAdded && this.genderAdded;
    }

});

export default NewsletterWidget;
