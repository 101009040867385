const setKey = (key, value) => localStorage.setItem(key, value);
const getKey = (key) => localStorage.getItem(key);
const deleteKey = (key) => localStorage.removeItem(key);

const getKeys = (arr) => arr.reduce((acc, curr) => {
    acc[curr] = getKey(curr);
    return acc;
}, {});

const deleteKeys = (arr) => arr.map(key => deleteKey(key));

const setKeys = (arr) => arr.map(entry => setKey(entry[0], entry[1]));


export {
    setKey,
    getKey,
    deleteKey,
    getKeys,
    deleteKeys,
    setKeys
};
