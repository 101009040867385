import $ from "jquery";

import "../../scss/widgets/contact.scss";
import gettext, {interpolate} from "../gettext";
import {emailRegexp} from "../validation/validation-checks";
import WidgetBase from "./rainbow-base";

/*
Contact widget is a simple form for contacting 3 different editorial (or
customer service) departments. Currently we have Startpagina Algemeen,
Startpagina GoeieVraag and Startpagina Thema’s. Each has a specific mailto
address.

There are prefilled subjects available in a pulldown specific for each
department.

An exception in the business rules: when the subject contains "Ik wil
adverteren op Startpagina", or "Ik wil een link aanmelden" (see Python code)
it will use the webmaster mailto address.

Validation is implemented in the frontend for the user experience and in
the backend for security reasons.
 */

const subjects = {
    startpagina: [
        gettext("I would like to submit a link"),
        gettext("I want to report a broken link"),
        gettext("I have a technical problem"),
        gettext("I have a problem with my account"),
        gettext("I want to delete my account"),
        gettext("I want to advertise on Startpagina"),
        gettext("I have a different question"),
    ],
    goeievraag: [
        gettext("I want to report an inappropriate question or answer"),
        gettext("I have a problem with my account"),
        gettext("I want to delete my account"),
        gettext("I want to report a broken link"),
        gettext("I have a technical problem"),
        gettext("I want to advertise on GoeieVraag"),
        gettext("I have a different question"),
    ],
    themapagina: [
        gettext("I have a question or complaint about a thema page"),
        gettext("I would like to start a thema page"),
        gettext("I would like to submit a link"),
        gettext("I want to report a broken link"),
        gettext("I have a technical problem"),
        gettext("I have a problem with my account"),
        gettext("I want to delete my account"),
        gettext("I want to advertise on a thema page"),
        gettext("I have a different question"),
    ]
};

export class ContactWidget extends WidgetBase {

    constructor(element) {
        super(element);
        this.form = this.element.find("form");
        this.editorField = this.element.find("#editor-select");
        this.subjectField = this.element.find("#subject-select");
        this.errorsElement = this.element.find(".errors");
        this.submitMessage = this.element.find(".onsubmit-message");
        this.counter = this.element.find("#counter");
        this.message = this.element.find("#email-message");
        this.emailMessageLimit = 1000;

        this.changeSubjects();
        this.counter.text(`${0} / ${this.emailMessageLimit}`);

        this.on({
            "change #editor-select": () => this.changeSubjects(),
            "click .contact-submit": e => {
                e.preventDefault();
                this.submitForm();
                return false;
            },
            "keyup #email-message": () => this.updateCharactersCounter()
        });
    }

    getSubjects(editor) {
        return subjects[editor];
    }

    changeSubjects() {
        const editor = this.editorField.val(),
            subjects = this.getSubjects(editor),
            subjectsSelect = this.element.find("#subject-select");
        subjectsSelect.find("option:gt(0)").remove();
        for (let i = 0; i < subjects.length; i++) {
            subjectsSelect.append(
                $("<option></option>").attr("value", subjects[i])
                    .text(subjects[i])
            );
        }
    }

    validateFields() {
        /**
         * Validate form fields client side .
         */
        const subject = this.subjectField.val(),
            title = this.element.find("#title-select").val(),
            name = this.element.find("#contact-name").val(),
            email = this.element.find("#contact-email").val(),
            emailConfirm = this.element.find("#contact-email-confirm").val(),
            message = this.element.find("#email-message").val(),
            errors = [];

        if (subject === "default") {
            errors.push(gettext("You have not selected a subject."));
        }

        if (title === "default") {
            errors.push(gettext("Please select a title."));
        }

        if (name.replace(/\s/g, "") === "") {
            errors.push(gettext("Please enter your name."));
        }

        if (!emailRegexp.test(email)) {
            errors.push(gettext("The email address you entered is not complete."));
        }

        if (email !== emailConfirm) {
            errors.push(gettext("The email addresses you entered do not match."));
        }

        if (message.replace(/\s/g, "") === "") {
            errors.push(gettext("Please enter your message."));
        }

        if (message.length > this.emailMessageLimit) {
            errors.push(
                interpolate(
                    gettext(
                        "Make sure this value does not exceed %s characters (currently %s)."),
                    [this.emailMessageLimit, this.message.val().length]
                )
            );
        }

        const attachmentField = this.element.find(".attachment");
        if (attachmentField[0].files.length > 0) {
            // Only validate when a file has been selected
            const attachment = attachmentField[0].files[0];
            if (attachment.name.search(/\.(png|jpg|jpeg)$/i) === -1) {
                errors.push(gettext("The attachment is not the right type (only JPG/PNG)"));
            }
            if (attachment.size > 1024 * 1024) {
                errors.push(gettext("The attachment is too big (max 1 MB)"));
            }
        }

        this.errorsElement.empty();

        for (const error of errors) {
            this.errorsElement.append($("<div />").text(error));
        }
        if (errors.length > 0) {
            this.errorsElement.show();
        } else {
            this.errorsElement.hide();
        }

        return errors.length === 0;
    }

    getSubmitUrl() {
        return "/api/contact";
    }

    submitForm() {
        if (!this.validateFields()) {
            return;
        }

        WidgetBase.request({
            url: this.getSubmitUrl(),
            type: "POST",
            data: new FormData(this.form[0]),
            contentType: false,
            processData: false,
        }).then(
            () => {
                this.showSubmitMessage();
            },
            (xhr) => {
                this.displaySubmitErrors(xhr.responseJSON && xhr.responseJSON.errors);
            }
        );
    }

    displaySubmitErrors(serverErrors) {
        this.errorsElement.empty();

        if (serverErrors) {
            for (const field in serverErrors) {
                for (const error of serverErrors[field]) {
                    this.errorsElement.append($("<div>").text(error));
                }
            }
        } else {
            this.errorsElement.append($("<div />").text(gettext("Unable to submit the contact form")));
        }

        this.errorsElement.show();
    }

    showSubmitMessage() {
        const name = this.element.find("#contact-name").val(),
            email = this.element.find("#contact-email").val();

        this.submitMessage.find(".user-name").text(name);
        this.submitMessage.find(".user-email").text(email);
        this.submitMessage.show();

        this.form.hide();
    }

    updateCharactersCounter() {
        const count = this.message.val().length;
        if (count > this.emailMessageLimit) {
            this.counter.css("color", "var(--red-500)");
        }
        this.counter.text(`${this.message.val().length} / ${this.emailMessageLimit}`);
    }
}
