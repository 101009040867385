const setUrl = (apiUrl, params) => {
    const url = new URL(apiUrl, location.origin);
    if (params) {
        url.search = new URLSearchParams(params);
    }
    return url;
};

const request = async (url, headers) => await fetch(url, {
    method: "GET",
    headers,
})
    .then((res) => res.text())
    .then(data => data)
    .catch(err => console.error(err));

export const getComponentData = (apiUrl, params, json = false) => {
    const url = setUrl(apiUrl, params);
    if (json) {
        return request(url, {
            "Content-type": "application/json"
        });
    } else {
        return request(url, {
            "Content-type": "text/html;charset=UTF-8"
        });
    }
};
