import cookies from "js-cookie";

/*

Search history is kept as an array of queries, from oldest to most recent.

When you enter a new search query, it is added to the end of the array. Only
the ten most recent entries are kept.

Going back and forward moves an internal pointer. The pointer initially points
to the most recent entry.

When you enter a new search query and the pointer is at some earlier point in
the history, the current query and the new query are added to the end of the
array. For example, if you you've entered these four queries:

    query 1 -> query 2 -> query 3 -> query 4
                                     ^^^^^^^

And you press 'previous' twice:

    query 1 -> query 2 -> query 3 -> query 4
               ^^^^^^^

Then enter 'query 5', the history will look like this:

    query 1 -> query 2 -> query 3 -> query 4 -> query 2 -> query 5
                                                           ^^^^^^^
*/

export default class SearchHistory {
    constructor() {
        this.maxAllowed = 10;
        this.history = [];
        this.pointer = 0;
        this.readCookie();
    }

    static getInstance() {
        if (SearchHistory._instance === undefined) {
            SearchHistory._instance = new SearchHistory();
        }
        return SearchHistory._instance;
    }

    readCookie() {
        const cookieValue = cookies.get("search-history");
        if (cookieValue) {
            this.history = cookieValue.split(",");
            this.pointer = this.history.length - 1;
        }
    }

    storeCookie() {
        cookies.set("search-history", JSON.stringify(this.history.join(",")));
    }

    hasPrevious() {
        return this.pointer > 0;
    }

    previous() {
        if (this.hasPrevious()) {
            this.pointer -= 1;
            return this.history[this.pointer];
        }
        return null;
    }

    hasNext() {
        return this.pointer < this.history.length - 1;
    }

    next() {
        if (this.hasNext()) {
            this.pointer += 1;
            return this.history[this.pointer];
        }
        return null;
    }

    current() {
        return this.history.length ? this.history[this.pointer] : null;
    }

    add(query) {
        if (this.current() === query) {
            return;
        }

        // If the pointer is not pointing to the end, add the current query.
        if (this.pointer < this.history.length - 1) {
            this.history.push(this.history[this.pointer]);
        }

        // Insert the query at the end of the history.
        this.history.push(query);

        // Keep only the most recent entries.
        if (this.history.length >= this.maxAllowed) {
            this.history = this.history.slice(-this.maxAllowed);
        }

        // Move the pointer to the end of the history.
        this.pointer = this.history.length - 1;

        this.storeCookie();
    }
}
