import Alpine from "alpinejs";

Alpine.store("Overlay", {
    overlayActive: false,
    overlayType: null,
    componentActive: null,
    body: null,

    init() {
        this.body = document.querySelector("body");
    },

    toggle(component, overlayType = null) {
        this.overlayActive = !this.overlayActive;
        if (component !== "moreMenu") {
            this.toggleBodyScroll();
        }
        this.overlayType = overlayType;
        this.componentActive = this.componentActive !== component ? component : null;
    },

    toggleBodyScroll() {
        if (this.overlayActive) {
            this.body.setAttribute("style", `position:fixed;top:-${document.documentElement.scrollTop}px;width:100%;overflow-y:scroll;`);
        } else {
            this.body.removeAttribute("style");
        }
    }
});
