import {hasAllOptIns, noConsentMessage} from "../consent";
import {createObserver} from "../helpers/object-observer";

const RadioWidget = () => ({
    station: "",
    noConsentMsg: null,
    src: null,

    init() {
        const station = new URLSearchParams(location.search);
        createObserver(this.$root, this.isInView.bind(this));
        this.station = station.get("station");
        this.setSrc();

        this.$watch("$store.ProfileAgent.data", (data) => {
            this.station = data.radio_station;
            this.setSrc();
        });
    },

    setSrc() {
        const {dataset} = this.$refs.iframe;
        const {src} = dataset;
        if (this.station) {
            this.src = `${src}#${this.station.replace(" ", "+")}`;
        } else {
            this.src = src;
        }
    },

    isInView(entries) {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                hasAllOptIns() ?
                    this.$refs.iframe.setAttribute("src", this.src) :
                    this.noConsentMsg = noConsentMessage;
            }
        });
    }
});

export default RadioWidget;
