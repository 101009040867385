const OpeningHoursWidget = () => ({
    url: "https://www.openingstijden.nl/zoeken/",
    location: "",

    init() {
        this.location = this.$store.ProfileAgent.currentLocation;

        this.$watch("$store.ProfileAgent.currentLocation", (location) => {
            this.location = location;
        });

    },

    submit() {
        const search = this.$refs.searchField?.value;
        const url = new URL(this.url);
        url.searchParams.set("location", this.location);
        url.searchParams.set("search", search);
        url.searchParams.set("trackEventLabel", search);
        window.open(url, "_blank");
    }
});

export default OpeningHoursWidget;
